//  Api Service

const Prod_BaseUrl = process.env.REACT_APP_PROD_URL
// const Stage_BaseUrl = process.env.REACT_APP_STAGE_URL;
const Stage_BaseUrl = "https://stgfastapi.imadeasong.com";
const Test_BaseUrl = process.env.REACT_APP_TEST_URL;

const environments = {
  production: Stage_BaseUrl,
};

const activeEnvironment = environments.production;

const apiUrl = {
  // Login
  login: activeEnvironment + "/login",
  UserSignUp: activeEnvironment + "/signup",

  //Logout
  logout: activeEnvironment + "/logout",

  // User
  getUserDetails: activeEnvironment + "/get_user_details",
  userReview: activeEnvironment + "/update_user_status",
  forgotPassword: activeEnvironment + "/forgot_password",
  resetPassword: activeEnvironment + "/reset_password",

  // Credit
  creditBalance: activeEnvironment + "/credit_balance",
  deductCredit: activeEnvironment + "/deduct_credit",

  // Generate song
  generate: activeEnvironment + "/generate",

  //upload audios
  uploadAudio: activeEnvironment + "/upload_audio",

  //Get Collection as per user login
  getCollection: activeEnvironment + "/get_collection",
  generateCustomSong: activeEnvironment + "/custom_generate",
  generateLyrics: activeEnvironment + "/create_lyrics",

  //download audio
  downloadAudio: activeEnvironment + "/download_song",

  // get payment details
  getPaymentDetails: activeEnvironment + "/get-payment-details",
  sendPaymentDetails: activeEnvironment + "/payment",
  createOrder: activeEnvironment + "/create-order",
  failedPayment: activeEnvironment + "/failed-payment",

  //Free trial
  freeTrial: activeEnvironment + "/get_free_trial_count",

  //Deduct free trial
  deductFreeTrial: activeEnvironment + "/deduct_free_trial_count",

  //Verify Otp
  verifyOtp: activeEnvironment + "/verify-registered-otp",

  // Resend Otp
  resendOtp: activeEnvironment + "/resend-registered-otp",
  // Razorpay
  razorpayCheckout: "https://checkout.razorpay.com/v1/checkout.js",

  //Open Ai
  customAi: activeEnvironment + "/fetch_ai_response",

  //Contact Us
  contactUs: activeEnvironment + "/contact-us",

  // Delete Audio
  deleteAudio: activeEnvironment + "/delete_user_songs",

  //Google Login
  googleSocialLogin: activeEnvironment + "/google-login",

  //Audio Watermark id
  watermarkAudio: activeEnvironment + "/create_watermark_audio",

  //Get Stram Blob for Playing song
  getStreamBlob: activeEnvironment + "/get_stream_blob",

  //Generate Slide Show & get All Genrated video
  generateSlideShow: activeEnvironment + "/generate-slideshow",
  downloadSlideShow: activeEnvironment + "/download_video",
  generatedCollageVideo: activeEnvironment + "/get_collage_collection",
  deleteCollageVideo: activeEnvironment + "/delete_collage_video",
  downloadCollageVideo: activeEnvironment + "/get_video_file",

  //Get Payment Details
  getPaymentHistory: activeEnvironment + "/get_payment_history",

  // update user details
  updateUser: activeEnvironment + "/update_user",

  // IP and data Fetch
  postIpData: activeEnvironment + "/add-ip-address",

  // Homedata fetch
  fetchHomeCollectionData: activeEnvironment + "/fetch_data",

  // audio clonning
  fetchVoiceAudioCloning: activeEnvironment + "/all_voices",
  generateAiCloneVideo: activeEnvironment + "/projects/{job_id}",
  getImageUrlClone: activeEnvironment + "/add_image",
  uploadAudioClone: activeEnvironment + "/ai_audio",
  submitVoiceClone: activeEnvironment + "/add_character",
  getAiCloneStatus: activeEnvironment + "/ai_video_status",
  // getAiCloneStatus: activeEnvironment + "/ai_video_status",
  downloadAiCloneVideo: activeEnvironment + "/download_ai_video",
  deleteAiCloneVideo: activeEnvironment + "/delete_ai_video",
  getImageOpinion: activeEnvironment + "/analyze_face",

  //Image Video
  imgVideo: activeEnvironment + "/generate-video",

  //Valantines
  fetchOccassion: activeEnvironment + '/fetch-occasions',
  saveOccassionForm: activeEnvironment + '/save-occasion-form',
  createOccassionOrder: activeEnvironment + '/create-occasion-order',
  occasionPayment: activeEnvironment + '/occasion-payment',
  redeemCoupon: activeEnvironment + '/redeem-coupon-code',
  ValentinesDelivery: activeEnvironment + '/get_occasion_data/{id}',
  invitationMail: activeEnvironment + '/send-invitaion-coupon-email',
  paymentFailDetails: activeEnvironment + '/occasion-failed-payment',



  // track count page

  trackCountVideoURl:activeEnvironment+"/track-visit"
};

export default apiUrl;
